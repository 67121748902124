/* eslint-disable @next/next/no-img-element */
'use client';
import { useClientTranslation } from '@/hook/translate';
import { Image } from '@nextui-org/react';
import NextImage from 'next/image';
import { MindfulAudioImg } from '@/config/img/mindfulAudio';

const imageLoader = ({ src }: { src: string }) => {
  return src;
};

export default function HomeHeader() {
  const { t } = useClientTranslation();
  return (
    <>
      <header className='max-md:hidden w-full h-[670px] bg-[#E7FBB0] py-[75px] flex justify-center'>
        <div className='box-wrap flex mx-auto'>
          <div className='relative min-w-[380px] h-full'>
            <div className='absolute left-0 top-11 text-[100px] leading-[120px] font-black z-20' style={{ WebkitTextStroke: '4px #E7FFA9' }}>
              <p>{t('homeText1', 'mindfulAudio')}</p>
              <p className='text-[#155FF9]'>{t('homeText2', 'mindfulAudio')}</p>
            </div>
          </div>
          <div className='w-[520px] relative'>
            <NextImage loader={ imageLoader } priority src={MindfulAudioImg.HeaderImage} width={520} height={520} alt='image' />
            {/* <Image as={ NextImage } priority src={MindfulAudioImg.HeaderImage} width={520} height={520} alt='image' /> */}
            <img src={MindfulAudioImg.HeaderEar} width={134} height={197} alt='ear' className='absolute top-[210px] left-[-161px]' />
            <img src={MindfulAudioImg.HeaderBook} width={194} height={133} alt='book' className='absolute top-[120px] right-[-211px]' />
          </div>
          <div className='min-w-[380px] h-full relative'>
            <div className='absolute right-0 bottom-12 text-[100px] leading-[120px] font-black z-20' style={{ WebkitTextStroke: '4px #E7FFA9' }}>
              <p>{t('homeText3', 'mindfulAudio')}</p>
              <p className='text-[#155FF9]'>{t('homeText4', 'mindfulAudio')}</p>
            </div>
          </div>
        </div>
      </header>
      <header className='hidden max-md:block bg-[#E7FBB0] pt-6 pb-12'>
        <div className='w-full relative flex justify-center pt-[30px]'>
          <Image src={MindfulAudioImg.HeaderImage} width={240} height={240} alt='image' />
          <img src={MindfulAudioImg.HeaderEar} width={87} height={132} alt='ear' className='absolute top-[10px] left-[32px]' />
          <img src={MindfulAudioImg.HeaderBook} width={120} height={85} alt='book' className='absolute bottom-1 right-1' />
        </div>
        <h2 className='px-[15px] text-38-52 text-center font-black pt-3'>
          <span>{t('homeText1', 'mindfulAudio')}</span>
          <span className='text-[#155FF9]'>{t('homeText2', 'mindfulAudio')}</span>
        </h2>
        <h2 className='px-[15px] text-38-52 text-center font-black'>
          <span>{t('homeText3', 'mindfulAudio')}</span>
          <span className='text-[#155FF9]'>{t('homeText4', 'mindfulAudio')}</span>
        </h2>
      </header>
    </>
  );
}
