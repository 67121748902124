'use client';
import { mindful } from '@/types/audioResData';
import React, { useCallback, useEffect, useState } from 'react';

import { imageLoader } from '@/utils';
import clsx from 'clsx';
import Link from 'next/link';
import NextImage from 'next/image';

interface IProps {
  topData: mindful.TCategory[],
  subData: mindful.TCategory[],
  activeCategory: string;
  category: string[];
}

export default function TopCategory(props: IProps) {
  const {
    topData,
    subData,
    activeCategory,
    category,
  } = props;
  const topic = category[1];
  const [startIndex, setStartIndex] = useState(0);

  const sliceTopData = useCallback((name: string) => {
    let idx = topData.findIndex((item) => item._name === name);
    if (idx > -1 && (idx - 2) > 0) {
      setStartIndex(idx - 2);
      return topData.slice(idx - 2, idx + 3);
    } else if (idx + 2 >= topData.length) {
      setStartIndex(topData.length - 5);
      return topData.slice(-5);
    } else {
      setStartIndex(0);
      return topData.slice(0, 5);
    }
  }, [topData]);
  const [showTopData, setShowTopData] = useState<mindful.TCategory[]>([]);


  const handleChangeShowTopData = (dir: string) => {
    let idx = startIndex;
    if (dir === 'left' && startIndex > 0) {
      idx = idx - 1;
    } else if (dir === 'right' && startIndex + 5 < topData.length) {
      idx = idx + 1;
    }
    setStartIndex(idx);
    setShowTopData(topData.slice(idx, idx + 5));
  };

  useEffect(() => {
    const data = sliceTopData(props.category[0]);
    setShowTopData(data);
  }, [props.category, sliceTopData]);

  return (
    <>
      {
        showTopData.length > 0 ?
          <div className='w-full'>
            <div className='pc-category  px-[150px] pt-[50px]  max-md:hidden'>
              <div className="wrap relative flex items-center justify-center">
                {
                  showTopData?.map((item) => {
                    return (
                      <div
                        className={
                          clsx('relative h-[128px] w-[256px] px-5 flex items-center text-center  overflow-hidden mr-6 last:mr-0  cursor-pointer font-bold',
                            item._name === props.category[0]
                              ? 'bg-[#CDF564] text-black'
                              : 'bg-cover bg-no-repeat bg-black/60 text-white',
                          )
                        }
                        style={{ backgroundImage: item._name !== props.category[0] ? `url(${encodeURI(item.iconPath)})` : '' }}
                        key={item._id}
                      >
                        <Link href={`/home/${item._name}/all/1`}>
                          <span className='w-[256px] cursor-pointer first:w-[208px] last:w-[256px] text-center text-24-35 break-words'>
                            {item.name}
                          </span>
                        </Link>
                      </div>
                    );
                  })
                }
                {
                  topData?.length >= 5 ?
                    <span key={'left'} className='absolute top-0 left-0 flex items-center justify-center w-[100px] h-[128px] bg-[url("/home-left-cover.png")]'>
                      <NextImage
                        src='/home-left.png'
                        alt='left-category'
                        loader={imageLoader}
                        className='cursor-pointer'
                        width={50}
                        height={50}
                        onClick={() => handleChangeShowTopData('left')}
                      />
                    </span>
                    : null
                }
                {
                  topData?.length >= 5 ?
                    <span key={'right'} className='absolute top-0  right-6 flex items-center justify-center w-[100px] h-[128px] bg-[url("/home-right-cover.png")]'>
                      <NextImage
                        src='/home-right.png'
                        alt='right-category'
                        loader={imageLoader}
                        className='cursor-pointer'
                        height={50}
                        width={50}
                        onClick={() => handleChangeShowTopData('right')}
                      />
                    </span>
                    : null
                }
              </div>
            </div>
            <div className='hidden max-md:flex pt-[25px] flex-nowrap overflow-auto'>
              {
                topData?.map((cate) => {
                  return (
                    <div
                      className={
                        clsx('h-20 flex justify-center items-center grow-0 shrink mr-3',
                          cate._name === props.category[0]
                            ? 'bg-[#CDF564] text-black'
                            : 'bg-cover bg-no-repeat bg-[url("/home-category-3.png")] text-white ',
                        )}
                      style={{ backgroundImage: cate._name !== props.category[0] ? `url(${encodeURI(cate.iconPath)})` : '' }}
                      key={cate._id}
                    >
                      <Link href={`/home/${cate._name}/all/1`} className=' w-40 break-words text-center font-semibold'>{cate.name}</Link>
                    </div>
                  );
                })
              }
            </div>
            <section className='sub-list'>
              {/* pc */}
              <div className='px-[150px] w-full pt-[30px] pb-20 overflow-auto max-md:hidden'>
                <div className='wrap flex flex-nowrap overflow-auto'>
                  {
                    subData?.map((item) => {
                      return (
                        <Link href={`/home/${activeCategory}/${item._name}/1`} key={item._id} className={
                          clsx('mr-[10px] px-[25px] py-3 shrink-0 border border-solid border-[#CBCBCB] rounded-3xl cursor-pointer',
                            topic === item._name && 'bg-[#CDF564] border-none',
                          )
                        }>
                          {item.name}
                        </Link>
                      );
                    })
                  }
                </div>

              </div>

              {/* mobile */}
              <div className='w-full flex-nowrap  px-[15px] pt-[15px] pb-6 overflow-auto hidden max-md:flex'>
                {
                  subData?.map((item) => {
                    return (
                      <Link
                        key={item._id}
                        href={`/home/${activeCategory}/${item._name}/1`}
                        className={
                          clsx('mr-2 px-5 py-[10px] text-14-19 border border-solid border-[#CBCBCB] rounded-3xl cursor-pointer grow shrink-0',
                            topic === item._name && 'bg-[#CDF564] border-none',
                          )
                        }
                      >
                        <span
                        >{item.name}</span>
                      </Link>
                    );
                  })
                }
              </div>
            </section>
          </div>
          : null
      }
    </>
  );
}
